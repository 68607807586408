<template>
  <div class="widgets">
    <iframe :src="statUrl" frameborder="0"></iframe>
  </div>
</template>

<script>
export default {
  name: "Statistics",
  mounted() {
    this.loadWidget();
  },
  props: {
    matchId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      language: this.$i18n.locale,
      statUrl: `https://s5.sir.sportradar.com/choplifegamingmts/${this.$i18n.locale}/match/${this.matchId}`,
    };
  },
  methods: {
    loadWidget() {},
  },
};
</script>

<style scoped>
.widgets {
  width: 100%;
  height: 500px;
}

.widgets iframe {
  width: 100%;
  margin: 0 auto;
  height: 100%;
}
</style>
