<template>
  <div class="market-wrapper">
    <section class="">
      <div
        class="market-row"
        data-toggle="collapse"
        :data-target="'#' + show_direction"
        :aria-controls="show_direction"
        :aria-expanded="isOpen"
        @click="toggleCollapse"
      >
        <div class="market-name">
          <img class="mr-2" style="width: 20px" src="/menu/star.svg" />
          <span
            style="display: block; text-wrap: wrap; word-break: break-all"
            >{{ market_name }}</span
          >
        </div>
        <img
          :class="{ rotate: isOpen }"
          src="/svg/arrow-down.svg"
          alt="arrow-down"
        />
      </div>
      <div
        style="background-color: var(--background-color)"
        class="collapse"
        :class="{ show: isOpen }"
        :id="show_direction"
      >
        <div
          v-if="!locked"
          :class="getRowClass(outcome.length)"
          class="odd-row"
        >
          <Odd
            v-for="o in outcome"
            :key="o.outcome_id"
            v-bind:id="o.odd_id"
            v-bind:disabled="isLocked(o)"
            v-bind:sport_id="fixture.sport_id"
            v-bind:market_name="market_name"
            v-bind:home_team="fixture.home_team"
            v-bind:away_team="fixture.away_team"
            v-bind:pick="o"
            v-bind:show_direction="show_direction"
            v-bind:match_id="fixture.match_id"
            v-bind:market_id="market_id"
            v-bind:outcome_id="o.outcome_id"
            v-bind:probability="o.probability"
            v-bind:specifier="specifier"
            v-bind:active="o.active"
            v-bind:status="status"
            v-bind:odds="o.odds"
            v-bind:alias="o.alias"
            v-bind:producer_id="fixture.producer_id"
            v-bind:producer_status="producer_status"
            v-bind:previous_odds="o.previous_odds"
          />
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import Odd from "../Odd.vue";

export default {
  components: {
    Odd,
  },
  name: "Outcome",
  props: {
    outcome: {
      type: Array,
      required: true,
    },
    market_id: {
      required: true,
    },
    market_name: {
      required: true,
    },
    specifier: {
      required: true,
    },
    status_name: {
      required: true,
    },
    status: {
      required: true,
    },
    producer_status: {
      required: true,
    },
    producer_id: {
      required: true,
    },
    fixture: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      isOpen: true,
      locked: false,
      directionClass: "",
    };
  },
  mounted() {
    // console.log(this.outcome, "outcome");
    // this.locked = this.isLocked(this.outcome[0]);
  },

  methods: {
    toggleCollapse() {
      this.isOpen = !this.isOpen;
    },

    isLocked(o) {
      if (parseInt(this.producer_id) === 1) {
        // console.log(
        //   "Producer STATUS -----====" + parseInt(this.producer_status)
        // );
        // console.log("ODD ACTIVE -----====" + o.active);
        // console.log("ODD STATUS -----====" + parseInt(this.status));
        // console.log("producer_id -----====" + parseInt(this.producer_id));
        return (
          parseInt(this.status) > 0 ||
          parseInt(o.active) === 0 ||
          parseInt(this.producer_status) === 0
        );
      } else {
        return parseInt(this.status) > 0 || parseInt(o.active) === 0;
      }
    },

    getRowClass(count) {
      if (count === 2) {
        return "two"; // 2 columns
      } else if (count === 3) {
        return "three"; // 3 columns
      } else {
        return "four"; // 4 columns by default
      }
    },

    getStatusClass: function () {
      this.statusClass = this.locked ? "lock" : "";
    },
  },
};
</script>

<style scoped src="./index.css"></style>
