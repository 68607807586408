<template>
  <div>
    <div v-if="loading">
      <LoadingMatch />
    </div>
    <div class="body" v-else>
      <div class="scoreline">
        <div class="btn-wrap" @click="goBack()">
          <BackArrow />
        </div>

        <span>{{ formatDate(fixture?.date) }}</span>
        <span>
          {{ fixture.country }} -
          {{ fixture.tournament }}
        </span>

        <div class="team-details">
          <div class="home">
            <div class="team">
              <p>{{ fixture.home_team }}</p>
            </div>
          </div>
          <div class="score">
            <span class="score_text">{{ fixture?.home_score }}</span>
            <span class="score_text">vs</span>
            <span class="score_text">{{ fixture?.away_score }}</span>
          </div>
          <div class="away">
            <div class="team">
              <p>{{ fixture.away_team }}</p>
            </div>
          </div>
        </div>
      </div>
      <div class="select-rules">
        <p
          :class="{ active: activeTab === 'matchOdds' }"
          @click="setActive('matchOdds')"
        >
          {{ $t("matchOdds") }}
        </p>
        <p
          :class="{ active: activeTab === 'animation' }"
          @click="setActive('animation')"
        >
          {{ $t("liveTracker") }}
        </p>
        <p
          :class="{ active: activeTab === 'statistics' }"
          @click="setActive('statistics')"
        >
          {{ $t("statistics") }}
        </p>
      </div>

      <div v-if="activeTab === 'matchOdds'">
        <div class="catsec-home select-sport">
          <span
            v-for="sport in sportsList"
            :key="sport.market_group_id"
            :class="[
              'cattit-home',
              {
                active: selectedSport.market_group_id === sport.market_group_id,
              },
            ]"
            :style="{
              border:
                selectedSport.market_group_is === sport.market_group_id
                  ? '2px solid #a31212'
                  : '',
            }"
            @click="
              setSportMenu(sport.market_group_id, sport.market_group_name)
            "
          >
            <img
              :style="{
                display: sport.icon ? '' : 'none',
              }"
              :src="sport.icon"
              class="menu-icon-sports-svg"
            />
            <span :v-if="sport.market_group_name">
              {{ sport.market_group_name }}
            </span>
          </span>

          <span
            v-for="sport in marketFilters"
            :key="sport.market_group_id"
            :class="[
              'cattit-home',
              {
                active: selectedSport.market_group_id === sport.market_group_id,
              },
            ]"
            :style="{
              border:
                selectedSport.market_group_id === sport.market_group_id
                  ? '2px solid #a31212'
                  : '',
            }"
            @click="
              setSportMenu(sport.market_group_id, sport.market_group_name)
            "
          >
            <img
              :style="{
                display: sport.icon ? '' : 'none',
              }"
              :src="sport.icon"
              class="menu-icon-sports-svg"
            />
            <span :v-if="sport.market_group_name">
              {{ sport.market_group_name }}
            </span>
          </span>
        </div>

        <div v-if="loadingMarket"><LoadingMatch :gamesLoad="true" /></div>
        <div v-else>
          <Outcome
            v-for="(m, index) in visibleMarkets"
            :key="getKey(index)"
            :market_id="m.market_id"
            :market_name="m.market_name"
            :specifier="m.specifier"
            :status_name="m.status_name"
            :status="m.status"
            :fixture="fixture"
            :producer_status="fixture.producer_status"
            :producer_id="fixture.producer_id"
            :outcome="m.outcomes"
          />
        </div>

        <div class="px-2">
          <section class="promo-card-wrapper mb-3">
            <div
              style="background-color: var(--background-color)"
              class="text-dark p-2"
              data-toggle="collapse"
              data-target="#1"
              aria-expanded="false"
              aria-controls="1"
            >
              <strong>
                {{ fixture.home_team }} - {{ fixture.away_team }} {{ $t("onlineBetting") }}: {{ $t("odds") }}, {{ $t("forecasts") }}, {{ $t("onlineBroadcast") }}
              </strong>
              <span style="float: right"
                ><img style="width: 15px" src="/arrow-down.png"
              /></span>
            </div>
            <div
              style="background-color: var(--background-color)"
              class="promo-content text-dark collapse p-2"
              id="1"
            >
              <p>
                {{ fixture.date }} {{ $t("inThe") }} {{ fixture.tournament }} {{ $t("matchBetween") }} {{ fixture.home_team }} {{$t("and")}}
                {{ fixture.away_team }} {{$t("teams")}}. {{ $t("bettingOddMatch") }}
              </p>
              <h6>
                {{ $t("historicalPerformance") }} {{ fixture.home_team }} -
                {{ fixture.away_team }}
              </h6>
              <p>
                {{ $t("eventPageInfo") }} {{ fixture.tournament }} standings available.
                {{ $t("infographicTab") }}
                 {{ $t("analyticalData") }}
                {{ fixture.home_team }} or {{ fixture.away_team }} {{ $t("basedOnAdvantages") }}
              </p>
              <h6>
                Betting Odds for {{ fixture.home_team }} -
                {{ fixture.away_team }}
              </h6>
              <p>
                {{ $t("bestOdds") }}
                {{ fixture.home_team }} - {{ fixture.away_team }}. {{ $t("bettingOptionss") }} {{ $t("learnOdds") }}
              </p>
              <h6>
                {{ fixture.home_team }} - {{ fixture.away_team }}. Betting Tips
                and Predictions
              </h6>
              <p>
                {{ $t("chopbetAnalist") }}
              </p>
              <h6>
                {{ $t("watch") }} {{ fixture.home_team }} - {{ fixture.away_team }} {{ $t("liveStreaming") }}
              </h6>
              <p>
                {{ $t("onlineBroadcastOf") }} {{ fixture.home_team }} vs.
                {{ fixture.away_team }} {{ $t("matchAvailability") }} {{ $t("watchAndBet") }} {{ $t("followStatistics") }} {{ fixture.home_team }} -
                {{ fixture.away_team }} {{ $t("matchStatistics") }}
              </p>
            </div>
          </section>
          <section class="promo-card-wrapper mb-3">
            <div
              style="background-color: var(--background-color)"
              class="text-dark p-2"
              data-toggle="collapse"
              data-target="#2"
              aria-expanded="false"
              aria-controls="2"
            >
              <strong>
                ☝️{{ $t("faqTitle") }} {{ fixture.home_team }} vs
                {{ fixture.away_team }} {{ $t("betting") }}
                <span style="float: right"
                  ><img style="width: 15px" src="/arrow-down.png"
                /></span>
              </strong>
            </div>
            <div
              style="background-color: var(--background-color)"
              class="promo-content text-dark collapse p-2"
              id="2"
            >
              <h6>
                ❓ {{ $t("oddsFor") }} {{ fixture.home_team }} vs
                {{ fixture.away_team }}?
              </h6>
              <p>
                ⚽ {{ $t("oddsDescription") }} {{ fixture.home_team }} vs
                {{ fixture.away_team }} {{ $t("likelihood") }}
                {{ fixture.tournament }} {{ $t("tournamentMatch") }}
                {{ fixture.home_team }} {{$t("and")}} {{ fixture.away_team }}. {{ $t("potentialPayout") }}
              </p>
              <h6>
                ❓ {{ $t("bettingMarket") }} {{ fixture.home_team }} vs
                {{ fixture.away_team }}?
              </h6>
              <p>
                ⚽ {{ $t("bettingMarket2") }} {{ fixture.home_team }} vs
                {{ fixture.away_team }} {{ $t("refersTo") }}
                {{ fixture.tournament }} {{ $t("tournamentMatch2") }} {{ $t("optionsInclude") }}
              </p>
              <h6>
                ❓ {{ $t("placeBetDuring") }} {{ fixture.home_team }} vs
                {{ fixture.away_team }} match?
              </h6>
              <p>
                ⚽ Yes, Chopbet offers live or in-play betting for
                {{ fixture.home_team }} vs {{ fixture.away_team }}
                match. This allows you to place bets while the game is ongoing,
                taking advantage of changing circumstances and dynamics.
              </p>
              <h6>
                ❓ {{ $t("placeBetmobile") }}
                {{ fixture.home_team }} vs {{ fixture.away_team }} match?
              </h6>
              <p>
                ⚽ {{ $t("mobileBetting") }} {{ fixture.tournament }} {{ $t("tournamentMatches") }}
                {{ fixture.home_team }} vs {{ fixture.away_team }}. {{ $t("convenience") }}
              </p>
            </div>
          </section>
        </div>
      </div>
      <div v-if="activeTab === 'animation'" class="animation">
        <LiveTracker :matchId="this.fixture.match_id" />
      </div>
      <div v-if="activeTab === 'statistics'">
        <Statistics :matchId="this.fixture.match_id" />
      </div>
    </div>
  </div>
</template>

<script>
import Outcome from "../../components/outcome/Outcome.vue";
import fixtures from "@/services/fixtures";
import BackArrow from "../../components/icons/BackArrow.vue";

import mqtt from "mqtt";
import LoadingMatch from "./LoadingMatch.vue";
import { formatDate } from "../../utils/formatter";
import Statistics from "./components/Statistics.vue";
import LiveTracker from "./components/LiveTracker.vue";

export default {
  name: "Match",
  components: {
    Outcome,
    BackArrow,
    LoadingMatch,
    Statistics,
    LiveTracker,
  },
  data: function () {
    return {
      selectedSport: {},
      sportsList: [
        {
          market_group_id: "all",
          market_group_name: "All",
        },
      ],
      activeTab: "matchOdds",
      iconSize: 24,
      match_id: 0,
      game_id: this.$route.params.game_id,
      lang: this.$i18n.locale,
      initial_fixture: {},
      market_group: {
        id: 0,
      },
      live: false,
      seconds: 0,
      mqttClient: false,
      iframeUrl: "",
      loading: false,
      marketFilters: [],
      loadingMarket: false,
      currenctGroup: [],
      visibleMarkets: [],
      allGroupedData: {},
      allMarketIds: null,
      fixture: {},
    };
  },

  mounted: function () {
    this.$store.dispatch("setCurrentPage", "match");
    // this.visibleMarkets = [];
    // this.allGroupedData = {};
    // this.allMarketIds = null;
    // this.currenctGroup = [];
    // this.game_id = this.$route.params.game_id;
    // console.log(this.game_id, "this.game_id");
    this.getMatch();
    this.subScribeToLive();
  },
  computed: {},
  methods: {
    formatDate,
    subScribeToLive: function () {
      let matchStatus = "+";
      let vm = this;
      const newTopic = `feeds/${matchStatus}/odds_change/${this.fixture.match_id}`;
      this.$store.dispatch("subscribeToTopic", newTopic);
      const mqttClient = this.$store.state.mqttClient;
      if (mqttClient) {
        mqttClient.on("message", function (topic, msg) {
          var payload = JSON.parse(msg.toString());
          vm.uxUpdate(payload);
        });
      }
    },
    goBack() {
      if (this.disable) {
        return;
      }
      if (this.closeBet) {
        this.closeBetSlip();
        return;
      }
      if (this.goto) {
        this.$router.push(this.goto);
      } else {
        this.$router.go(-1);
      }
    },
    setActive(tab) {
      this.activeTab = tab;
    },
    setSportMenu(id, name) {
      this.selectedSport = {
        market_group_id: id,
        market_group_name: name,
      };

      if (id === "all") {
        const allIds = Object.values(this.allGroupedData).flatMap((arr) =>
          arr.map((item) => item.market_id)
        );
        this.filterMarkets(allIds);
        return;
      }
      const allIds = this.allGroupedData[name].map((item) => item.market_id);
      this.filterMarkets(allIds);
    },
    initMqtt: function () {
      var vm = this;
      let matchStatus = "+"; // should be either prematch or live or + (all)
      let topics = [
        `feeds/${matchStatus}/odds_change/${this.fixture.match_id}`,
        "feeds/producer_status", // subscribe to all producer status updates
      ];

      if (this.mqttClient !== false) {
        this.mqttClient.end();
      }

      const options = {
        clean: true, // retain session
        connectTimeout: 4000, // Timeout period
        clientId: this.getClientID(),
        username: process.env.VUE_APP_URL_MQTT_USER,
        password: process.env.VUE_APP_URL_MQTT_PASS,
      };

      var client = mqtt.connect(process.env.VUE_APP_URL_MQTT_HOST, options);

      client.on("connect", function () {
        // subscribe to home page topics to update matches
        vm.jQuery.each(topics, function (k, v) {
          client.subscribe(v, function (err) {
            if (!err) {
              // console.log("subscribed to | " + v);
            }
          });
        });
      });
      client.on("message", function (topic, msg) {
        // message is Buffer
        var payload = JSON.parse(msg.toString());
        vm.uxUpdate(payload);
      });

      this.mqttClient = client;
    },

    listenForOddsChange: function () {
      var vm = this;

      vm.EventBus.$on(
        "match:reload:" + this.fixture.match_id,
        function (updates) {
          vm.updateFixture(updates);
        }
      );
    },

    getKey: function (index) {
      return Math.random()
        .toString(10)
        .replace("0.", "outcomes-id-" + index + "-");
    },

    getColumnWidth: function (a) {
      if (a.length === 2) {
        return "col-6";
      }

      if (a.length === 3) {
        return "col-4";
      }

      return "col-auto";
    },

    getHomeCompetitorName: function (matchName) {
      if (matchName === undefined) {
        return "";
      }

      return matchName.split("vs.")[0];
    },

    getAwayCompetitorName: function (matchName) {
      if (matchName === undefined) {
        return "";
      }
      return matchName.split("vs.")[1];
    },

    getMatch: async function () {
      var path = `/game/${this.game_id}`;
      // console.log(path, "path");
      try {
        this.loading = true;
        const res = await fixtures.get(path);
        var fixture = res.data;
        // console.log(fixture, "fixture");

        if (!fixture?.home_team || !fixture?.away_team) {
          const teams = fixture?.name?.split("vs.") || [];
          if (teams.length === 2) {
            fixture.home_team = teams[0].trim();
            fixture.away_team = teams[1].trim();
          }
        }
        this.fixture = fixture;
        var path2 = `/markets/by-sport/${res.data.sport_id}`;
        const filtersRes = await fixtures.get(path2);
        this.loading = false;
        this.selectedSport = filtersRes.data[0];
        const grouped = new Map();
        const allGroupUnique = new Map();
        filtersRes.data.forEach((group) => {
          allGroupUnique.set(group.market_group_id, {
            market_group_name: group.market_group_name,
            market_group_id: group.market_group_id,
          });
          if (grouped.has(group.market_group_name)) {
            grouped.get(group.market_group_name).push(group);
          } else {
            grouped.set(group.market_group_name, [group]);
          }
        });

        const groupedObject = Object.fromEntries(grouped);
        this.marketFilters = Array.from(allGroupUnique.values());
        this.allGroupedData = groupedObject;

        if (this.selectedSport.market_group_id === "all") {
          const allIds = Object.values(this.allGroupedData).flatMap((arr) =>
            arr.map((item) => item.market_id)
          );
          this.filterMarkets(allIds);
          // console.log("here first .....");
        } else {
          const allIds = Object.values(groupedObject).flatMap((arr) =>
            arr.map((item) => item.market_id)
          );
          this.filterMarkets(allIds);
          // console.log("here 2 first .....");
        }
        if (parseInt(fixture.producer_id) !== 3) {
          this.live = true;
        } else {
          this.live = false;
        }
        this.initMqtt();
        this.listenForOddsChange();
      } catch (error) {
        console.log(error);
      } finally {
        this.loading = false;
      }
    },

    updateFixture: function (updates) {
      var vm = this;
      var fixture = updates;

      vm.live = parseInt(fixture.producer_id) === 1;

      var formatted_18 = {};
      var formatted_238 = {};
      var formatted_166 = {};

      var totals_18 = formatted_18;
      var totals_238 = formatted_238;
      var totals_166 = formatted_166;

      fixture.totals_18 = totals_18;
      fixture.totals_238 = totals_238;
      fixture.totals_166 = totals_166;

      vm.$store.dispatch("setFixture", fixture);
      vm.autoRefreshUI(vm.$vnode.tag);
    },

    getOddDirectionClass: function (outcome) {
      if (parseInt(outcome.active) === 0 || parseInt(outcome.status) != 1) {
        return "";
      }

      if (parseFloat(outcome.odd) >= parseFloat(outcome.previous_odds)) {
        return "arrow green up";
      }

      return "arrow red down";
    },

    filterMarkets: function (currenctGroup) {
      const allMarket = this.fixture.market;
      // const currenctGroup = this.allMarketIds;
      // console.log(currenctGroup, "currenctGroup currenctGroup---------");
      const filteredMarkets = allMarket
        ?.filter((market) => currenctGroup.includes(market.market_id))
        ?.sort(
          (a, b) =>
            currenctGroup.indexOf(a.market_id) -
            currenctGroup.indexOf(b.market_id)
        );

      this.visibleMarkets = filteredMarkets;
    },
  },
  beforeDestroy: function () {
    if (this.mqttClient !== false) {
      this.mqttClient.end();
      this.mqttClient = false;
    }
  },
  destroyed: function () {
    if (this.mqttClient !== false) {
      this.mqttClient.end();
      this.mqttClient = false;
    }
  },
  watch: {
    $route: {
      immediate: true,
      // handler(to) {
      // react to route changes...
      // document.title = to.params.name.replace(/[^a-z0-9+]+/gi, " ");
      // document.description =
      //   "The best boosted odds for " +
      //   to.params.name.replace(/[^a-z0-9+]+/gi, " ") +
      //   " get free bonus, freebets and many cashback offers ";
      // },
    },
  },
};
</script>
<style src="./index.css" scoped></style>
