<template>
  <div class="skeleton-wrapper">
    <div v-for="n in count" :key="n">
      <div class="head page_container" v-if="!gamesLoad">
        <div class="skeleton-item"></div>
        <div class="skeleton-item"></div>
        <div class="skeleton-item"></div>
      </div>
      <div class="head2 page_container" v-if="!gamesLoad">
        <div class="skeleton-item"></div>
        <div class="skeleton-item"></div>
        <div class="skeleton-item"></div>
      </div>

      <div class="content">
        <div class="title">
          <div class="skeleton-item"></div>
        </div>
        <div class="market">
          <div class="skeleton-item"></div>
          <div class="skeleton-item"></div>
          <div class="skeleton-item"></div>
        </div>
      </div>
      <div class="content">
        <div class="title">
          <div class="skeleton-item"></div>
        </div>
        <div class="market">
          <div class="skeleton-item"></div>
          <div class="skeleton-item"></div>
          <div class="skeleton-item"></div>
        </div>
        <div class="market">
          <div class="skeleton-item"></div>
          <div class="skeleton-item"></div>
          <div class="skeleton-item"></div>
        </div>
      </div>
      <div class="content">
        <div class="title">
          <div class="skeleton-item"></div>
        </div>
        <div class="market">
          <div class="skeleton-item"></div>
          <div class="skeleton-item"></div>
          <div class="skeleton-item"></div>
        </div>
      </div>
      <div class="content">
        <div class="title">
          <div class="skeleton-item"></div>
        </div>
        <div class="market">
          <div class="skeleton-item"></div>
          <div class="skeleton-item"></div>
          <div class="skeleton-item"></div>
        </div>
      </div>
      <div class="content">
        <div class="title">
          <div class="skeleton-item"></div>
        </div>
        <div class="market">
          <div class="skeleton-item"></div>
          <div class="skeleton-item"></div>
          <div class="skeleton-item"></div>
        </div>
        <div class="market">
          <div class="skeleton-item"></div>
          <div class="skeleton-item"></div>
          <div class="skeleton-item"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "LoadingMatch",
  props: {
    count: {
      type: Number,
      default: 1,
    },
    gamesLoad: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style scoped>
.skeleton-wrapper {
  display: flex;
  flex-direction: column;
  & > div {
    border-bottom: 1px solid var(--fixture-border);
    padding: 8px 0px;
    padding-bottom: 0;
  }
}

.skeleton-item {
  width: 100%;
  height: 20px;
  background-color: var(--skeleton);
  margin-bottom: 10px;
  animation: pulse 1.5s infinite;
}

@keyframes pulse {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}

.head {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 2rem;
  border-bottom: 1px solid var(--fixture-border);
  margin-bottom: 2rem;
  padding: 8px 0;
  & > div {
    width: 35%;
    height: 13px;
  }
  & > div:nth-child(2) {
    width: 5%;
  }
}

.head2 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid var(--fixture-border);
  padding: 8px 0;
  & > div {
    width: 25%;
    height: 13px;
  }
  & > div:nth-child(2) {
    width: 20%;
  }
}
.content {
  margin-top: 1rem;
}

.content .title {
  width: 95%;
  margin: 0 auto;
  div {
    margin-top: 1px;
    height: 40px;
  }
  /* margin-bottom: 2rem; */
}

.content .market {
  width: 95%;
  margin: 0 auto;
  height: 13px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 2rem;
  div {
    margin-left: 4px;
    height: 40px;
    width: 30%;
  }
}

.last {
  width: 100px;
  margin-left: auto;
  margin-right: 0;
  height: 13px;
}
</style>
